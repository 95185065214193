import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const LoginButton = ({ isDarkMode }) => {
  const { loginWithRedirect } = useAuth0();

  return (
    <button
      onClick={() => loginWithRedirect()}
      className={`
        px-6 py-2
        text-lg font-semibold
        rounded-md
        transition-colors duration-300
        ${isDarkMode
          ? 'bg-yellow-400 text-gray-900 hover:bg-yellow-300'
          : 'bg-yellow-400 text-gray-900 hover:bg-yellow-500'}
        focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-opacity-50
      `}
    >
      Log In
    </button>
  );
};

export default LoginButton;
