import React, { useState, useEffect, useCallback } from 'react';
import { Search, ChevronRight, Phone, Globe, Star, Mail, Building, User, Briefcase, Tag, MapPin, Facebook, Twitter, Linkedin, Instagram, Eye } from 'lucide-react';
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const QueueItemCard = ({ item, isDarkMode, onViewResults }) => {
  return (
    <div className={`${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'} p-4 rounded-lg shadow-md mb-4`}>
      <h3 className="font-bold text-lg mb-2">Search: {item.category} in {item.zip_codes.join(', ')}</h3>
      <p>Status: {item.status}</p>
      <p>Progress: {item.progress}%</p>
      <p>Created: {new Date(item.created_at).toLocaleString()}</p>
      {item.status === 'completed' && (
        <button
          onClick={() => onViewResults(item)}
          className={`mt-2 flex items-center px-3 py-1 rounded-lg ${isDarkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-600'
            } text-white`}
        >
          <Eye size={16} className="mr-2" /> View Results
        </button>
      )}
    </div>
  );
};

const BusinessFinder = ({ isDarkMode }) => {
  const { user } = useAuth0();
  const navigate = useNavigate();
  const [businesses, setBusinesses] = useState([]);
  const [category, setCategory] = useState('');
  const [zipCodes, setZipCodes] = useState('');
  const [limit, setLimit] = useState(100);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [queueItems, setQueueItems] = useState([]);
  const [selectedQueueItem, setSelectedQueueItem] = useState(null);
  const [isDeepSearch, setIsDeepSearch] = useState(false);

  const fetchQueueStatus = useCallback(async () => {
    if (!user) return;
    try {
      const response = await axios.get(`https://sunlab-8da6b753879c.herokuapp.com/api/queue-status?userId=${user.name}`);
      // Filter out completed items
      const activeItems = response.data.filter(item => item.status !== 'completed');
      setQueueItems(activeItems);
    } catch (error) {
      console.error('Error fetching queue status:', error);
    }
  }, [user]);

  useEffect(() => {
    const interval = setInterval(fetchQueueStatus, 5000);
    return () => clearInterval(interval);
  }, [fetchQueueStatus]);

  const addToQueue = async () => {
    if (!user) {
      setError('User must be logged in to add a search to the queue.');
      return;
    }
    setIsLoading(true);
    setError(null);

    try {
      const zipCodesArray = zipCodes.split(',').map(zip => zip.trim());
      const response = await axios.post('https://sunlab-8da6b753879c.herokuapp.com/api/add-to-queue', {
        userId: user.name,
        category,
        zipCodes: zipCodesArray,
        limit,
        isDeepSearch
      });
      setQueueItems(prevItems => [...prevItems, response.data]);
    } catch (error) {
      console.error('Error adding search to queue:', error);
      setError('An error occurred while adding the search to the queue. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleViewResults = (queueItem) => {
    setSelectedQueueItem(queueItem);
    setBusinesses(queueItem.results || []);
  };

  return (
    <div className={`business-finder max-w-6xl mx-auto p-6 ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-800'} min-h-screen`}>
      <h2 className={`text-3xl font-bold mb-8 text-left ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>Lead Generator</h2>
      <div className={`filters ${isDarkMode ? 'bg-gray-800' : 'bg-white'} p-6 rounded-lg shadow-md mb-8`}>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
          <div>
            <label htmlFor="category" className={`block text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'} mb-1`}>Business Category</label>
            <input
              id="category"
              type="text"
              placeholder="e.g., Restaurant, Plumber"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              className={`w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 ${isDarkMode ? 'bg-gray-700 text-white border-gray-600' : 'bg-white text-gray-800 border-gray-300'}`}
            />
          </div>
          <div>
            <label htmlFor="zipCodes" className={`block text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'} mb-1`}>Zip Codes (comma-separated)</label>
            <input
              id="zipCodes"
              type="text"
              placeholder="Enter zip codes (e.g., 12345, 67890)"
              value={zipCodes}
              onChange={(e) => setZipCodes(e.target.value)}
              className={`w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 ${isDarkMode ? 'bg-gray-700 text-white border-gray-600' : 'bg-white text-gray-800 border-gray-300'}`}
            />
          </div>
          <div>
            <label htmlFor="limit" className={`block text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'} mb-1`}>Limit</label>
            <input
              id="limit"
              type="number"
              placeholder="Number of results"
              value={limit}
              onChange={(e) => setLimit(e.target.value)}
              className={`w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 ${isDarkMode ? 'bg-gray-700 text-white border-gray-600' : 'bg-white text-gray-800 border-gray-300'}`}
            />
          </div>
        </div>
        <div className="flex items-center mb-4">
          <input
            id="deepSearch"
            type="checkbox"
            checked={isDeepSearch}
            onChange={(e) => setIsDeepSearch(e.target.checked)}
            className="mr-2"
          />
          <label htmlFor="deepSearch" className={`text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
            Deep Search (Slower but more comprehensive)
          </label>
        </div>
        <button
          onClick={addToQueue}
          className="w-full bg-yellow-fill border-yellow-border border-0.5 text-white p-3 rounded-lg hover:bg-yellow-hover disabled:bg-blue-300 transition duration-300 flex items-center justify-center"
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Adding to Queue...
            </>
          ) : (
            <>
              <Search className="mr-2" size={20} />
              Add to Queue
            </>
          )}
        </button>
      </div>

      {error && (
        <div className={`${isDarkMode ? 'bg-red-900 border-red-700 text-red-300' : 'bg-red-100 border-red-500 text-red-700'} border-l-4 p-4 mb-8`} role="alert">
          <p className="font-bold">Error</p>
          <p>{error}</p>
        </div>
      )}

      <div className="flex justify-between items-center mt-8 mb-4">
        <h3 className={`text-2xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>Queue Status</h3>
        <button
          onClick={() => navigate('/search-logs')}
          className={`flex items-center px-4 py-2 rounded-lg ${isDarkMode ? 'bg-yellow-fill border-yellow-border border-0.5 hover:bg-yellow-hover' : 'bg-blue-500 hover:bg-blue-600'
            } text-white`}
        >
          Completed Searches <ChevronRight size={20} className="ml-2" />
        </button>
      </div>
      <div className="queue-items grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {queueItems.map(item => (
          <QueueItemCard
            key={item.id}
            item={item}
            isDarkMode={isDarkMode}
            onViewResults={handleViewResults}
          />
        ))}
      </div>

      {selectedQueueItem && (
        <>
          <h3 className="text-2xl font-bold mt-8 mb-4">
            Search Results: {selectedQueueItem.category} in {selectedQueueItem.zip_codes.join(', ')}
          </h3>
          <div className="results grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {businesses.map((business, index) => (
              <div key={index} className={`business-card ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'} p-6 rounded-lg shadow-md hover:shadow-lg transition duration-300`}>
                <h3 className="font-bold text-xl mb-2">{business.name}</h3>
                <p className={`text-sm mb-2 flex items-center ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                  <Building size={16} className="mr-2" />
                  {business.company}
                </p>
                <p className={`text-sm mb-2 flex items-center ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                  <User size={16} className="mr-2" />
                  {`${business.firstName} ${business.lastName}`.trim() !== '' ? `${business.firstName} ${business.lastName}` : 'N/A'}
                </p>
                <p className={`text-sm mb-2 flex items-center ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                  <Briefcase size={16} className="mr-2" />
                  {business.role}
                </p>
                <p className={`text-sm mb-2 flex items-center ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                  <Tag size={16} className="mr-2" />
                  {business.industry}
                </p>
                <p className={`text-sm mb-2 flex items-center ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                  <Tag size={16} className="mr-2" />
                  {business.category}
                </p>
                <p className={`text-sm mb-2 flex items-center ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                  <MapPin size={16} className="mr-2" />
                  {business.state}
                </p>
                <p className={`text-sm mb-2 flex items-center ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                  <Phone size={16} className="mr-2" />
                  {business.phoneNumber}
                </p>
                <p className={`text-sm mb-2 flex items-center ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                  <Globe size={16} className="mr-2" />
                  {business.website !== 'N/A' ? (
                    <a href={business.website} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                      Visit Website
                    </a>
                  ) : (
                    'N/A'
                  )}
                </p>
                <p className={`text-sm mb-2 flex items-center ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                  <Mail size={16} className="mr-2" />
                  {business.email}
                </p>
                <p className={`text-sm flex items-center ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                  <Star size={16} className="mr-2" />
                  {business.reviews} reviews
                </p>
                {business.description && (
                  <p className={`text-sm mt-2 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                    <strong>Description:</strong> {business.description}
                  </p>
                )}
                {business.keywords && (
                  <p className={`text-sm mt-2 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                    <strong>Keywords:</strong> {business.keywords}
                  </p>
                )}
                {business.socialMedia && Object.keys(business.socialMedia).some(key => business.socialMedia[key]) && (
                  <div className={`mt-2 flex items-center ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                    <strong className="mr-2">Social:</strong>
                    {business.socialMedia.facebook && (
                      <a href={business.socialMedia.facebook} target="_blank" rel="noopener noreferrer" className="mr-2">
                        <Facebook size={16} />
                      </a>
                    )}
                    {business.socialMedia.twitter && (
                      <a href={business.socialMedia.twitter} target="_blank" rel="noopener noreferrer" className="mr-2">
                        <Twitter size={16} />
                      </a>
                    )}
                    {business.socialMedia.linkedin && (
                      <a href={business.socialMedia.linkedin} target="_blank" rel="noopener noreferrer" className="mr-2">
                        <Linkedin size={16} />
                      </a>
                    )}
                    {business.socialMedia.instagram && (
                      <a href={business.socialMedia.instagram} target="_blank" rel="noopener noreferrer" className="mr-2">
                        <Instagram size={16} />
                      </a>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default BusinessFinder;