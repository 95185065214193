import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Play, ArrowLeft } from 'lucide-react';
import axios from 'axios';

const ProjectDetails = ({ isDarkMode, startTimeTracking }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [project, setProject] = useState(null);
  const [note, setNote] = useState('');
  const [notes, setNotes] = useState([]);

  const fetchProjectDetails = useCallback(async () => {
    try {
      const response = await axios.get(`https://sunlab-dev-76f40b386902.herokuapp.com/api/projects/${id}`);
      setProject(response.data);
    } catch (error) {
      console.error('Error fetching project details:', error);
    }
  }, [id]);

  const fetchProjectNotes = useCallback(async () => {
    try {
      const response = await axios.get(`https://sunlab-dev-76f40b386902.herokuapp.com/api/projects/${id}/notes`);
      setNotes(response.data);
    } catch (error) {
      console.error('Error fetching project notes:', error);
    }
  }, [id]);

  useEffect(() => {
    fetchProjectDetails();
    fetchProjectNotes();
  }, [fetchProjectDetails, fetchProjectNotes]);

  const handleAddNote = async () => {
    if (note.trim()) {
      try {
        await axios.post(`https://sunlab-dev-76f40b386902.herokuapp.com/api/projects/${id}/notes`, { content: note });
        setNote('');
        fetchProjectNotes();
      } catch (error) {
        console.error('Error adding note:', error);
      }
    }
  };

  const handleStartTimeTracking = () => {
    startTimeTracking(id, project.name);
  };

  if (!project) {
    return <div>Loading...</div>;
  }

  return (
    <div className={`container font-SpaceGrotesk mx-auto px-4 py-8 ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>
      <button
        onClick={() => navigate('/projects')}
        className={`mb-4 flex items-center ${isDarkMode ? 'text-blue-300' : 'text-blue-600'}`}
      >
        <ArrowLeft size={20} className="mr-2" /> Back to Projects
      </button>
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold">{project.name}</h1>
        <button
          onClick={handleStartTimeTracking}
          className={`flex items-center px-4 py-2 rounded ${
            isDarkMode ? 'bg-green-600 hover:bg-green-700' : 'bg-green-500 hover:bg-green-600'
          } text-white`}
        >
          <Play size={20} className="mr-2" /> Start Time Tracking
        </button>
      </div>
      <div className={`grid grid-cols-1 md:grid-cols-2 gap-6 mb-8 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
        <div>
          <p><strong>Category:</strong> {project.category}</p>
          <p><strong>Client Name:</strong> {project.clientName}</p>
          <p><strong>Client Contact:</strong> {project.clientContact}</p>
        </div>
        <div>
          <p><strong>Status:</strong> {project.status}</p>
          <p><strong>Created At:</strong> {new Date(project.created_at).toLocaleString()}</p>
        </div>
      </div>
      <div className={`mb-8 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
        <h2 className="text-2xl font-bold mb-2">Description</h2>
        <p>{project.description}</p>
      </div>
      <div>
        <h2 className="text-2xl font-bold mb-4">Notes</h2>
        <div className="mb-4">
          <textarea
            value={note}
            onChange={(e) => setNote(e.target.value)}
            className={`w-full p-2 rounded ${
              isDarkMode ? 'bg-gray-700 text-white' : 'bg-white text-gray-800'
            } border ${isDarkMode ? 'border-gray-600' : 'border-gray-300'}`}
            rows="3"
            placeholder="Add a note..."
          ></textarea>
          <button
            onClick={handleAddNote}
            className={`mt-2 px-4 py-2 rounded ${
              isDarkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-600'
            } text-white`}
          >
            Add Note
          </button>
        </div>
        <ul className={`space-y-2 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
          {notes.map((note) => (
            <li key={note.id} className={`p-2 rounded ${isDarkMode ? 'bg-gray-800' : 'bg-gray-100'}`}>
              <p>{note.content}</p>
              <p className="text-sm text-gray-500 mt-1">
                {new Date(note.created_at).toLocaleString()}
              </p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ProjectDetails;
