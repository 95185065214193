import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Send, Edit, Trash, CheckCircle, Settings as SettingsIcon, Save, ToggleLeft, ToggleRight, ChevronLeft, ChevronRight } from 'lucide-react';
import ContactDetails from './ContactDetails';

const AIEmailGenerator = ({ isDarkMode }) => {
  const [activeTab, setActiveTab] = useState('generate');
  const [contacts, setContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const [generatedEmail, setGeneratedEmail] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState('');
  const [showContactDetails, setShowContactDetails] = useState(false);
  const [selectedContactForEdit, setSelectedContactForEdit] = useState(null);
  const [generatedEmails, setGeneratedEmails] = useState([]);
  const [currentEmailIndex, setCurrentEmailIndex] = useState(-1);
  const [settings, setSettings] = useState({
    defaultName: '',
    defaultEmail: '',
    defaultCompany: '',
    defaultSignature: '',
    showOnlyEmailContacts: true
  });

  useEffect(() => {
    fetchContacts();
    loadSettings();
  }, []);

  const fetchContacts = async () => {
    try {
      const response = await axios.get('https://sunlab-dev-76f40b386902.herokuapp.com/api/contacts');
      if (Array.isArray(response.data)) {
        setContacts(response.data);
      } else {
        console.error('Unexpected response format:', response.data);
        setContacts([]);
        setError('Failed to fetch contacts. Unexpected data format.');
      }
    } catch (error) {
      console.error('Error fetching contacts:', error);
      setContacts([]);
      setError('Failed to fetch contacts. Please try again.');
    }
  };


  const handleDeleteContact = async (id) => {
    try {
      await axios.delete(`https://sunlab-dev-76f40b386902.herokuapp.com/api/contacts/${id}`);
      fetchContacts();
    } catch (error) {
      console.error('Error deleting contact:', error);
      setError('Failed to delete contact. Please try again.');
    }
  };

  const handleGenerateEmail = async () => {
    if (!selectedContact) {
      setError('Please select a contact first');
      return;
    }

    setIsGenerating(true);
    setError('');

    try {
      const response = await axios.post('https://sunlab-dev-76f40b386902.herokuapp.com/api/generate-email', {
        contactId: selectedContact.id,
        settings: settings
      });
      const newEmail = response.data.content;
      setGeneratedEmails([...generatedEmails, newEmail]);
      setCurrentEmailIndex(generatedEmails.length);
      setGeneratedEmail(newEmail);
    } catch (error) {
      console.error('Error generating email:', error);
      setError('Failed to generate email. Please try again.');
    } finally {
      setIsGenerating(false);
    }
  };

  const handleSaveEmail = async () => {
    try {
      await axios.post('https://sunlab-dev-76f40b386902.herokuapp.com/api/save-email', {
        contactId: selectedContact.id,
        content: generatedEmail
      });
      setError('Email saved successfully');
      const updatedEmails = [...generatedEmails];
      updatedEmails[currentEmailIndex] = generatedEmail;
      setGeneratedEmails(updatedEmails);
    } catch (error) {
      console.error('Error saving email:', error);
      setError('Failed to save email. Please try again.');
    }
  };

  const handleNextEmail = () => {
    if (currentEmailIndex < generatedEmails.length - 1) {
      setCurrentEmailIndex(currentEmailIndex + 1);
      setGeneratedEmail(generatedEmails[currentEmailIndex + 1]);
    }
  };

  const handlePreviousEmail = () => {
    if (currentEmailIndex > 0) {
      setCurrentEmailIndex(currentEmailIndex - 1);
      setGeneratedEmail(generatedEmails[currentEmailIndex - 1]);
    }
  };

  const loadSettings = () => {
    const savedSettings = localStorage.getItem('emailGeneratorSettings');
    if (savedSettings) {
      setSettings(JSON.parse(savedSettings));
    }
  };

  const saveSettings = () => {
    localStorage.setItem('emailGeneratorSettings', JSON.stringify(settings));
    setError('Settings saved successfully');
  };

  const handleDeleteEmail = () => {
    const newEmails = generatedEmails.filter((_, index) => index !== currentEmailIndex);
    setGeneratedEmails(newEmails);
    if (newEmails.length > 0) {
      const newIndex = Math.min(currentEmailIndex, newEmails.length - 1);
      setCurrentEmailIndex(newIndex);
      setGeneratedEmail(newEmails[newIndex]);
    } else {
      setCurrentEmailIndex(-1);
      setGeneratedEmail('');
    }
  };

  const toggleShowOnlyEmailContacts = () => {
    setSettings(prevSettings => ({
      ...prevSettings,
      showOnlyEmailContacts: !prevSettings.showOnlyEmailContacts
    }));
  };

  const filteredContacts = settings.showOnlyEmailContacts
    ? contacts.filter(contact => contact.email && contact.email.includes('@'))
    : contacts;

  const inputClass = `w-full ${isDarkMode ? 'bg-gray-700 text-white' : 'bg-gray-100 text-gray-800'} rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500`;
  const buttonClass = `${isDarkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-600'} text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline`;
  const tabClass = `px-4 py-2 font-semibold ${isDarkMode ? 'text-gray-300 hover:text-white' : 'text-gray-600 hover:text-gray-800'} border-b-2 border-transparent`;
  const activeTabClass = `${tabClass} ${isDarkMode ? 'border-white text-white' : 'border-blue-500 text-blue-500'}`;

  const handleEditContact = (contact) => {
    setSelectedContactForEdit(contact);
    setShowContactDetails(true);
  };

  const handleSaveContact = async (updatedContact) => {
    try {
      const updatedContacts = contacts.map(contact =>
        contact.id === updatedContact.id ? updatedContact : contact
      );
      setContacts(updatedContacts);
      if (selectedContact && selectedContact.id === updatedContact.id) {
        setSelectedContact(updatedContact);
      }
      setShowContactDetails(false);
      setSelectedContactForEdit(null);
    } catch (error) {
      console.error('Error updating contact:', error);
      setError('Failed to update contact. Please try again.');
    }
  };

  const renderContactList = () => (
    <ul className="space-y-2 max-h-96 overflow-y-auto">
      {filteredContacts.map((contact) => (
        <li key={contact.id} className={`p-2 rounded ${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'} flex justify-between items-center`}>
          <span>{contact.company}</span>
          <div>
            <button
              onClick={() => setSelectedContact(contact)}
              className={`${buttonClass} mr-2 bg-green-500 hover:bg-green-600`}
            >
              <CheckCircle size={16} />
            </button>
            <button
              onClick={() => handleEditContact(contact)}
              className={`${buttonClass} mr-2 bg-yellow-500 hover:bg-yellow-600`}
            >
              <Edit size={16} />
            </button>
            <button
              onClick={() => handleDeleteContact(contact.id)}
              className={`${buttonClass} bg-red-500 hover:bg-red-600`}
            >
              <Trash size={16} />
            </button>
          </div>
        </li>
      ))}
    </ul>
  );

  return (
    <div className={`container mx-auto px-4 py-8 ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-800'}`}>
      <h1 className="text-3xl font-bold mb-6">AI Email Generator</h1>

      {error && <p className="text-red-500 mb-4">{error}</p>}

      <div className="mb-4 border-b border-gray-200">
        <nav className="-mb-px flex">
          <button
            className={activeTab === 'generate' ? activeTabClass : tabClass}
            onClick={() => setActiveTab('generate')}
          >
            Generate Email
          </button>
          <button
            className={activeTab === 'settings' ? activeTabClass : tabClass}
            onClick={() => setActiveTab('settings')}
          >
            Settings
          </button>
        </nav>
      </div>

      <div className={`p-4 ${isDarkMode ? 'bg-gray-800' : 'bg-white'} rounded-lg`}>
        {activeTab === 'generate' && (
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
            <div className="lg:col-span-2">
              <h2 className="text-2xl font-semibold mb-4">Generate Email</h2>
              {selectedContact && (
                <div className={`p-4 rounded mb-4 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-200'}`}>
                  <h3 className="font-semibold mb-2">Selected Contact:</h3>
                  <p>{selectedContact.firstName} {selectedContact.lastName} - {selectedContact.company}</p>
                </div>
              )}
              <button
                onClick={handleGenerateEmail}
                disabled={!selectedContact || isGenerating}
                className={`${buttonClass} w-full ${(!selectedContact || isGenerating) && 'opacity-50 cursor-not-allowed'}`}
              >
                <Send size={20} className="inline-block mr-2" />
                {isGenerating ? 'Generating...' : 'Generate Email'}
              </button>
              {generatedEmail && (
                <div className="mt-4">
                  <div className="flex justify-between items-center mb-2">
                    <h3 className="text-xl font-semibold">Generated Email:</h3>
                    <div className="flex space-x-2">
                      <button className={`${buttonClass} bg-gray-500 hover:bg-gray-600`} onClick={handlePreviousEmail}>
                        <ChevronLeft size={20} />
                      </button>
                      <button className={`${buttonClass} bg-gray-500 hover:bg-gray-600`} onClick={handleNextEmail}>
                        <ChevronRight size={20} />
                      </button>
                      <button className={`${buttonClass} bg-red-500 hover:bg-red-600`} onClick={handleDeleteEmail}>
                        <Trash size={20} />
                      </button>
                    </div>
                  </div>
                  <textarea
                    value={generatedEmail}
                    onChange={(e) => setGeneratedEmail(e.target.value)}
                    className={`${inputClass} w-full h-64 mb-2`}
                  ></textarea>
                  <div className="flex justify-between">
                    <button className={buttonClass} onClick={() => setGeneratedEmail('')}>
                      <Edit size={20} className="inline-block mr-2" /> Edit Email
                    </button>
                    <button className={`${buttonClass} bg-green-500 hover:bg-green-600`} onClick={handleSaveEmail}>
                      <Save size={20} className="inline-block mr-2" /> Save Email
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div>
              <h3 className="text-xl font-semibold mb-2">Contact List</h3>
              {renderContactList()}
            </div>
          </div>
        )}

        {activeTab === 'settings' && (
          <div>
            <h2 className="text-2xl font-semibold mb-4">Settings</h2>
            <div className="space-y-4">
              <div>
                <label className="block mb-1">Default Name</label>
                <input
                  type="text"
                  value={settings.defaultName}
                  onChange={(e) => setSettings({...settings, defaultName: e.target.value})}
                  className={inputClass}
                />
              </div>
              <div>
                <label className="block mb-1">Default Email</label>
                <input
                  type="email"
                  value={settings.defaultEmail}
                  onChange={(e) => setSettings({...settings, defaultEmail: e.target.value})}
                  className={inputClass}
                />
              </div>
              <div>
                <label className="block mb-1">Default Company</label>
                <input
                  type="text"
                  value={settings.defaultCompany}
                  onChange={(e) => setSettings({...settings, defaultCompany: e.target.value})}
                  className={inputClass}
                />
              </div>
              <div>
                <label className="block mb-1">Default Signature</label>
                <textarea
                  value={settings.defaultSignature}
                  onChange={(e) => setSettings({...settings, defaultSignature: e.target.value})}
                  className={`${inputClass} h-24`}
                ></textarea>
              </div>
              <div className="flex items-center">
                <label className="flex-grow">Show only contacts with emails</label>
                <button
                  onClick={toggleShowOnlyEmailContacts}
                  className={`${buttonClass} flex items-center`}
                >
                  {settings.showOnlyEmailContacts ? (
                    <>
                      <ToggleRight size={20} className="mr-2" />
                      On
                    </>
                  ) : (
                    <>
                      <ToggleLeft size={20} className="mr-2" />
                      Off
                    </>
                  )}
                </button>
              </div>
              <button onClick={saveSettings} className={buttonClass}>
                <SettingsIcon size={20} className="inline-block mr-2" /> Save Settings
              </button>
            </div>
          </div>
        )}
      </div>
      {showContactDetails && (
        <ContactDetails
          contact={selectedContactForEdit}
          onClose={() => setShowContactDetails(false)}
          onSave={handleSaveContact}
          isDarkMode={isDarkMode}
        />
      )}
    </div>
  );
};

export default AIEmailGenerator;
