import React, { useState, useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { Play, Pause, Plus, X, Clock } from 'lucide-react';

const TimeTracker = ({ isDarkMode }) => {
  const { user } = useAuth0();
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState('');
  const [description, setDescription] = useState('');
  const [isTracking, setIsTracking] = useState(false);
  const [timeEntries, setTimeEntries] = useState([]);
  const [newProjectName, setNewProjectName] = useState('');
  const [currentEntryId, setCurrentEntryId] = useState(null);

  const fetchProjects = useCallback(async () => {
    try {
      const response = await axios.get('https://sunlab-dev-76f40b386902.herokuapp.com/api/projects');
      setProjects(response.data);
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  }, []);

  const fetchTimeEntries = useCallback(async () => {
    try {
      const response = await axios.get(`https://sunlab-dev-76f40b386902.herokuapp.com/api/time-entries?userId=${user.sub}`);
      setTimeEntries(response.data);
    } catch (error) {
      console.error('Error fetching time entries:', error);
    }
  }, [user.sub]);

  useEffect(() => {
    fetchProjects();
    fetchTimeEntries();
  }, [fetchProjects, fetchTimeEntries]);

  const handleStartTracking = async () => {
    if (!selectedProject) {
      alert('Please select a project before starting tracking.');
      return;
    }
    setIsTracking(true);
    try {
      const response = await axios.post('https://sunlab-dev-76f40b386902.herokuapp.com/api/time-entries', {
        projectId: selectedProject,
        userId: user.sub,
        startTime: new Date().toISOString(),
        description,
      });
      setCurrentEntryId(response.data.id);
    } catch (error) {
      console.error('Error starting time entry:', error);
      setIsTracking(false);
    }
  };

  const handleStopTracking = async () => {
    setIsTracking(false);
    try {
      await axios.put(`https://sunlab-dev-76f40b386902.herokuapp.com/api/time-entries/${currentEntryId}/stop`, {
        endTime: new Date().toISOString(),
      });
      setDescription('');
      setCurrentEntryId(null);
      fetchTimeEntries();
    } catch (error) {
      console.error('Error stopping time entry:', error);
    }
  };

  const handleCreateProject = async () => {
    if (newProjectName.trim()) {
      try {
        await axios.post('https://sunlab-dev-76f40b386902.herokuapp.com/api/projects', {
          name: newProjectName.trim(),
        });
        setNewProjectName('');
        fetchProjects();
      } catch (error) {
        console.error('Error creating project:', error);
      }
    }
  };

  const handleDeleteTimeEntry = async (id) => {
    try {
      await axios.delete(`https://sunlab-dev-76f40b386902.herokuapp.com/api/time-entries/${id}`);
      fetchTimeEntries();
    } catch (error) {
      console.error('Error deleting time entry:', error);
    }
  };

  const inputClass = `w-full ${isDarkMode ? 'bg-gray-700 text-white border-gray-600' : 'bg-white text-gray-800 border-gray-300'} border rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500`;
    const buttonClass = `${isDarkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-600'} text-white font-bold py-2 px-4 rounded-md focus:outline-none focus:shadow-outline transition duration-150 ease-in-out`;

    return (
      <div className={`container font-SpaceGrotesk mx-auto px-4 py-8 ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-800'}`}>
        <h2 className="text-3xl font-bold mb-8">Time Tracker</h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <h3 className="text-xl font-semibold mb-4">Start Tracking</h3>
            <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} shadow-md rounded-lg p-6 space-y-4`}>
              <select
                value={selectedProject}
                onChange={(e) => setSelectedProject(e.target.value)}
                className={inputClass}
              >
                <option value="">Select a project</option>
                {projects.map((project) => (
                  <option key={project.id} value={project.id}>{project.name}</option>
                ))}
              </select>
              <input
                type="text"
                placeholder="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className={inputClass}
              />
              {isTracking ? (
                <button onClick={handleStopTracking} className={`${buttonClass} bg-red-500 hover:bg-red-600 w-full`}>
                  <Pause size={20} className="inline-block mr-2" /> Stop Tracking
                </button>
              ) : (
                <button onClick={handleStartTracking} className={`${buttonClass} w-full`}>
                  <Play size={20} className="inline-block mr-2" /> Start Tracking
                </button>
              )}
            </div>

            <h3 className="text-xl font-semibold mt-8 mb-4">Create New Project</h3>
            <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} shadow-md rounded-lg p-6`}>
              <div className="flex space-x-2">
                <input
                  type="text"
                  placeholder="New project name"
                  value={newProjectName}
                  onChange={(e) => setNewProjectName(e.target.value)}
                  className={inputClass}
                />
                <button onClick={handleCreateProject} className={buttonClass}>
                  <Plus size={20} className="inline-block" />
                </button>
              </div>
            </div>
          </div>

          <div>
            <h3 className="text-xl font-semibold mb-4">Recent Time Entries</h3>
            <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} shadow-md rounded-lg p-6`}>
              <ul className="space-y-4">
                {timeEntries.map((entry) => (
                  <li key={entry.id} className={`flex justify-between items-center p-3 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-50'} rounded-md`}>
                    <div>
                      <p className="font-semibold">{entry.project_name}</p>
                      <p className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>{entry.description}</p>
                      <p className={`text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                        <Clock size={12} className="inline-block mr-1" />
                        {new Date(entry.start_time).toLocaleString()} - {entry.end_time ? new Date(entry.end_time).toLocaleString() : 'Ongoing'}
                      </p>
                    </div>
                    <button onClick={() => handleDeleteTimeEntry(entry.id)} className="text-red-500 hover:text-red-700 transition duration-150 ease-in-out">
                      <X size={20} />
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  };

  export default TimeTracker;
