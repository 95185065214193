import React, { useState, useEffect } from 'react';
import { Plus, ArrowRight, ArrowLeft } from 'lucide-react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import TechnologyCalculator from './TechnologyCalculator';
import MarketingCalculator from './MarketingCalculator';

const Projects = ({ isDarkMode }) => {
  const [projects, setProjects] = useState([]);
  const [showNewProjectForm, setShowNewProjectForm] = useState(false);
  const [newProject, setNewProject] = useState({
    category: '',
    name: '',
    description: '',
    clientName: '',
    clientContact: '',
    status: 'Active',
    calculatorData: null
  });
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    try {
      const response = await axios.get('https://sunlab-dev-76f40b386902.herokuapp.com/api/projects');
      setProjects(response.data);
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  };

  const handleNewProject = () => {
    setShowNewProjectForm(true);
    setCurrentStep(0);
  };

  const handleInputChange = (e) => {
    setNewProject({ ...newProject, [e.target.name]: e.target.value });
  };

  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleCalculatorData = (data) => {
    setNewProject(prev => ({
      ...prev,
      calculatorData: data
    }));
  };

  const handleSaveProject = async () => {
    try {
      await axios.post('https://sunlab-dev-76f40b386902.herokuapp.com/api/projects', newProject);
      setShowNewProjectForm(false);
      fetchProjects();
    } catch (error) {
      console.error('Error saving project:', error);
    }
  };

  const inputClass = `w-full ${isDarkMode ? 'bg-gray-700 text-white border-gray-600' : 'bg-white text-gray-800 border-gray-300'} border rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500`;
  const buttonClass = `${isDarkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-600'} text-white font-bold py-2 px-4 rounded-md focus:outline-none focus:shadow-outline transition duration-150 ease-in-out`;

  const renderProjectForm = () => {
    switch (currentStep) {
      case 0:
        return (
          <div className={`space-y-4 ${isDarkMode ? 'bg-gray-800' : 'bg-white'} shadow-md font-SpaceGrotesk rounded-lg p-6`}>
            <h2 className="text-2xl font-bold mb-4">Project Details</h2>
            <select
              name="category"
              value={newProject.category}
              onChange={handleInputChange}
              className={inputClass}
            >
              <option value="">Select Category</option>
              <option value="Technology">Technology</option>
              <option value="Marketing">Marketing</option>
            </select>
            <input
              type="text"
              name="name"
              placeholder="Project Name"
              value={newProject.name}
              onChange={handleInputChange}
              className={inputClass}
            />
            <textarea
              name="description"
              placeholder="Description"
              value={newProject.description}
              onChange={handleInputChange}
              className={inputClass}
            />
            <input
              type="text"
              name="clientName"
              placeholder="Client Name"
              value={newProject.clientName}
              onChange={handleInputChange}
              className={inputClass}
            />
            <input
              type="text"
              name="clientContact"
              placeholder="Client Contact"
              value={newProject.clientContact}
              onChange={handleInputChange}
              className={inputClass}
            />
            <button onClick={handleNextStep} className={`${buttonClass} w-full`}>
              Next <ArrowRight size={20} className="inline-block ml-2" />
            </button>
          </div>
        );
      case 1:
        return (
          <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} shadow-md rounded-lg p-6`}>
            <h2 className="text-2xl font-bold mb-4">Calculator</h2>
            {newProject.category === 'Technology' ? (
              <TechnologyCalculator isDarkMode={isDarkMode} onCalculate={handleCalculatorData} />
            ) : (
              <MarketingCalculator isDarkMode={isDarkMode} onCalculate={handleCalculatorData} />
            )}
            <div className="mt-4 flex justify-between">
              <button onClick={handlePreviousStep} className={`${buttonClass} bg-gray-500 hover:bg-gray-600`}>
                <ArrowLeft size={20} className="inline-block mr-2" /> Previous
              </button>
              <button onClick={handleSaveProject} className={`${buttonClass} bg-green-fill hover:bg-green-hover border-green-border border-0.5`}>
                Save Project
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className={`container mx-auto font-SpaceGrotesk px-4 py-8 ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-800'}`}>
      <div className="flex flex-col mb-6">
        <h1 className="text-3xl font-bold mb-4">Projects</h1>
        <div className="flex space-x-2">
          <button onClick={handleNewProject} className={`flex items-center px-4 py-2 rounded ${
            isDarkMode ? 'bg-yellow-fill hover:bg-yellow-hover border-yellow-border border-0.5' : 'bg-blue-500 hover:bg-blue-600'
          } text-white`}>
            <Plus size={20} className="mr-2" /> New Project
          </button>
        </div>
      </div>

      {showNewProjectForm ? (
        renderProjectForm()
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {projects.map((project) => (
            <Link
              key={project.id}
              to={`/projects/${project.id}`}
              className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} shadow-md rounded-[20px] p-6 cursor-pointer hover:shadow-lg transition-shadow duration-200`}
            >
              <h3 className="text-xl font-semibold mb-2">{project.name}</h3>
              <p className={`${isDarkMode ? 'text-gray-300' : 'text-gray-600'} mb-2`}>{project.clientName}</p>
              <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>{project.description}</p>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default Projects;
