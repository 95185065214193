import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import EmailSettings from './EmailSettings';

const ProfilePage = ({ isDarkMode }) => {
  const { user } = useAuth0();

  return (
    <div className={`container mx-auto px-4 py-8 ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-800'}`}>
      <h1 className="text-3xl font-bold mb-6">User Profile</h1>
      <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} shadow-md rounded-lg p-6`}>
        <div className="flex items-center mb-6">
          <img src={user.picture} alt="Profile" className="w-20 h-20 rounded-full mr-4" />
          <div>
            <h2 className="text-2xl font-semibold">{user.name}</h2>
            <p className={`${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>{user.email}</p>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <h3 className="text-xl font-semibold mb-2">User Information</h3>
            <p><strong>Nickname:</strong> {user.nickname}</p>
            <p><strong>Last Updated:</strong> {new Date(user.updated_at).toLocaleDateString()}</p>
          </div>
          <div>
            <h3 className="text-xl font-semibold mb-2">Additional Information</h3>
            <p><strong>Email Verified:</strong> {user.email_verified ? 'Yes' : 'No'}</p>
            <p><strong>Locale:</strong> {user.locale || 'Not specified'}</p>
          </div>
        </div>
      </div>
      <EmailSettings isDarkMode={isDarkMode} />
    </div>
  );
};

export default ProfilePage;
