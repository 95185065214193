import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { DollarSign, Clock, Plus, Minus } from 'lucide-react';

const stateTaxRates = {
  AL: 0.04,
  AK: 0,
  AZ: 0.056,
  AR: 0.065,
  CA: 0.0725,
  CO: 0.029,
  CT: 0.0635,
  DE: 0,
  FL: 0.06,
  GA: 0.04,
  HI: 0.04,
  ID: 0.06,
  IL: 0.0625,
  IN: 0.07,
  IA: 0.06,
  KS: 0.065,
  KY: 0.06,
  LA: 0.0445,
  ME: 0.055,
  MD: 0.06,
  MA: 0.0625,
  MI: 0.06,
  MN: 0.06875,
  MS: 0.07,
  MO: 0.04225,
  MT: 0,
  NE: 0.055,
  NV: 0.0685,
  NH: 0,
  NJ: 0.06625,
  NM: 0.05125,
  NY: 0.04,
  NC: 0.0475,
  ND: 0.05,
  OH: 0.0575,
  OK: 0.045,
  OR: 0,
  PA: 0.06,
  RI: 0.07,
  SC: 0.06,
  SD: 0.045,
  TN: 0.07,
  TX: 0.0625,
  UT: 0.0485,
  VT: 0.06,
  VA: 0.053,
  WA: 0.065,
  WV: 0.06,
  WI: 0.05,
  WY: 0.04,
  DC: 0.06,
};

const TechnologyCalculator = ({ isDarkMode, settings }) => {
  const [services, setServices] = useState([]);
  const [hourlyRate, setHourlyRate] = useState(settings?.defaultHourlyRate || 150);
  const [customService, setCustomService] = useState({ name: '', price: '', hours: 0 });
  const [complexity, setComplexity] = useState('medium');
  const [urgency, setUrgency] = useState('normal');
  const [selectedState, setSelectedState] = useState('CA');

  const predefinedServices = [
    { name: 'Custom Application Development', basePrice: 20000, baseHours: 133, minHourlyRate: 150, maxHourlyRate: 200 },
    { name: 'Mobile App Development', basePrice: 25000, baseHours: 166, minHourlyRate: 150, maxHourlyRate: 200 },
    { name: 'Web Development', basePrice: 10000, baseHours: 83, minHourlyRate: 120, maxHourlyRate: 180 },
    { name: 'UI/UX Design', basePrice: 5000, baseHours: 50, minHourlyRate: 100, maxHourlyRate: 150 },
    { name: 'Software Maintenance and Support', basePrice: 2000, baseHours: 20, minHourlyRate: 100, maxHourlyRate: 150 },
    { name: 'API Integration and Development', basePrice: 5000, baseHours: 41, minHourlyRate: 120, maxHourlyRate: 180 },
    { name: 'Prototyping', basePrice: 5000, baseHours: 50, minHourlyRate: 100, maxHourlyRate: 150 },
    { name: 'Design Optimization', basePrice: 5000, baseHours: 41, minHourlyRate: 120, maxHourlyRate: 170 },
    { name: '3D Printing', basePrice: 500, baseHours: 5, minHourlyRate: 90, maxHourlyRate: 130 },
    { name: 'Product Testing and Validation', basePrice: 5000, baseHours: 50, minHourlyRate: 100, maxHourlyRate: 150 },
    { name: 'Manufacturing Support', basePrice: 10000, baseHours: 83, minHourlyRate: 120, maxHourlyRate: 180 },
    { name: 'PCB Design', basePrice: 5000, baseHours: 41, minHourlyRate: 120, maxHourlyRate: 180 },
    { name: 'Material Selection', basePrice: 5000, baseHours: 50, minHourlyRate: 100, maxHourlyRate: 150 },
    { name: 'Machine Learning Models', basePrice: 20000, baseHours: 133, minHourlyRate: 150, maxHourlyRate: 250 },
    { name: 'Natural Language Processing (NLP)', basePrice: 20000, baseHours: 133, minHourlyRate: 150, maxHourlyRate: 250 },
    { name: 'Deep Learning Projects', basePrice: 30000, baseHours: 200, minHourlyRate: 150, maxHourlyRate: 250 },
    { name: 'AI Integration', basePrice: 15000, baseHours: 100, minHourlyRate: 150, maxHourlyRate: 250 },
    { name: 'Custom AI Solutions', basePrice: 20000, baseHours: 133, minHourlyRate: 150, maxHourlyRate: 250 },
    { name: 'Computer Vision', basePrice: 25000, baseHours: 166, minHourlyRate: 150, maxHourlyRate: 250 },
  ];

  const complexityFactors = useMemo(() => settings?.complexityFactors || {
    low: 0.8,
    medium: 1,
    high: 1.2,
  }, [settings]);

  const urgencyFactors = useMemo(() => settings?.urgencyFactors || {
    low: 0.9,
    normal: 1,
    high: 1.3,
  }, [settings]);

  useEffect(() => {
    if (settings) {
      setHourlyRate(settings.defaultHourlyRate);
    }
  }, [settings]);

  const adjustServicePrice = useCallback((service) => {
    const complexityFactor = complexityFactors[complexity];
    const urgencyFactor = urgencyFactors[urgency];
    let adjustedPrice, adjustedHours;

    switch (service.pricingModel) {
      case 'monthly':
        adjustedPrice = Math.round(service.basePrice * urgencyFactor);
        adjustedHours = service.baseHours;
        break;
      case 'perUnit':
        adjustedPrice = Math.round(service.basePrice * complexityFactor);
        adjustedHours = Math.round(service.baseHours * complexityFactor);
        break;
      case 'project':
      default:
        adjustedPrice = Math.round(service.basePrice * complexityFactor * urgencyFactor);
        adjustedHours = Math.round(service.baseHours * complexityFactor);
    }

    return {
      ...service,
      price: adjustedPrice,
      hours: adjustedHours,
    };
  }, [complexity, urgency, complexityFactors, urgencyFactors]);

  useEffect(() => {
    setServices(prevServices => prevServices.map(adjustServicePrice));
  }, [adjustServicePrice]);

  const addService = (service) => {
    const adjustedService = adjustServicePrice(service);
    setServices(prevServices => [...prevServices, adjustedService]);
  };

  const removeService = (index) => {
    setServices(prevServices => prevServices.filter((_, i) => i !== index));
  };

  const addCustomService = () => {
    if (customService.name && customService.price && customService.hours) {
      const adjustedService = adjustServicePrice({
        ...customService,
        basePrice: Number(customService.price),
        baseHours: Number(customService.hours),
        pricingModel: 'project',
      });
      setServices(prevServices => [...prevServices, adjustedService]);
      setCustomService({ name: '', price: '', hours: 0 });
    }
  };

  const totalPrice = services.reduce((sum, service) => sum + service.price, 0);
  const totalHours = services.reduce((sum, service) => sum + service.hours, 0);
  const taxRate = stateTaxRates[selectedState] || 0;
  const totalPriceWithTax = totalPrice * (1 + taxRate);

  const inputClass = `w-full ${isDarkMode ? 'bg-gray-700 text-white' : 'bg-gray-100 text-gray-800'} rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500`;
  const labelClass = 'block mb-2 font-medium';
  const buttonClass = `${isDarkMode ? 'bg-yellow-900 hover:bg-yellow-500' : 'bg-yellow-900 hover:bg-yellow-500'} text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline`;

  return (
    <div className={`w-full ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'} p-6 font-SpaceGrotesk shadow-lg`}>
      <h2 className="text-2xl font-bold mb-6">Technology Services Calculator</h2>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label className={labelClass}>Hourly Rate</label>
          <div className="flex items-center">
            <DollarSign size={20} className="mr-2" />
            <input
              type="number"
              value={hourlyRate}
              onChange={(e) => setHourlyRate(Number(e.target.value))}
              className={inputClass}
            />
          </div>
        </div>

        <div>
          <label className={labelClass}>Project Complexity</label>
          <select
            value={complexity}
            onChange={(e) => setComplexity(e.target.value)}
            className={inputClass}
          >
            <option value="low">Low</option>
            <option value="medium">Medium</option>
            <option value="high">High</option>
          </select>
        </div>

        <div>
          <label className={labelClass}>Project Urgency</label>
          <select
            value={urgency}
            onChange={(e) => setUrgency(e.target.value)}
            className={inputClass}
          >
            <option value="low">Low</option>
            <option value="normal">Normal</option>
            <option value="high">High</option>
          </select>
        </div>

        <div>
          <label className={labelClass}>State</label>
          <select
            value={selectedState}
            onChange={(e) => setSelectedState(e.target.value)}
            className={inputClass}
          >
            {Object.keys(stateTaxRates).map((state) => (
              <option key={state} value={state}>{state}</option>
            ))}
          </select>
        </div>

        <div className="md:col-span-2">
          <label className={labelClass}>Add Service</label>
          <select
            onChange={(e) => addService(JSON.parse(e.target.value))}
            className={inputClass}
          >
            <option value="">Select a service</option>
            {predefinedServices.map((service, index) => (
              <option key={index} value={JSON.stringify(service)}>
                {service.name} - ${service.basePrice}
              </option>
            ))}
          </select>
        </div>

        <div className="md:col-span-2">
          <label className={labelClass}>Custom Service</label>
          <input
            type="text"
            placeholder="Service name"
            value={customService.name}
            onChange={(e) => setCustomService({ ...customService, name: e.target.value })}
            className={`${inputClass} mb-2`}
          />
          <div className="flex items-center mb-2">
            <DollarSign size={20} className="mr-2" />
            <input
              type="number"
              placeholder="Base Price"
              value={customService.price}
              onChange={(e) => setCustomService({ ...customService, price: e.target.value })}
              className={inputClass}
            />
          </div>
          <div className="flex items-center mb-2">
            <Clock size={20} className="mr-2" />
            <input
              type="number"
              placeholder="Estimated Hours"
              value={customService.hours}
              onChange={(e) => setCustomService({ ...customService, hours: e.target.value })}
              className={inputClass}
            />
          </div>
          <button
            onClick={addCustomService}
            className={buttonClass}
          >
            <Plus size={20} className="mr-2 inline" /> Add Custom Service
          </button>
        </div>
      </div>

      <div className="mt-8">
        <h3 className="text-xl font-bold mb-2">Selected Services</h3>
        {services.map((service, index) => (
          <div key={index} className="flex justify-between items-center mb-2">
            <span>{service.name}</span>
            <div className="flex items-center">
              <span className="mr-2">${service.price} ({service.hours} hrs)</span>
              <button
                onClick={() => removeService(index)}
                className={`${isDarkMode ? 'bg-red-600 hover:bg-red-700' : 'bg-red-500 hover:bg-red-600'} text-white rounded-full p-1`}
              >
                <Minus size={16} />
              </button>
            </div>
          </div>
        ))}
      </div>

      <div className="mt-8 pt-4 border-t border-gray-300">
        <div className="text-xl font-bold">
          Total Price: ${totalPrice}
        </div>
        <div className="text-xl font-bold">
          Total Hours: {totalHours}
        </div>
        <div className="text-xl font-bold">
          Estimated Cost (Hourly): ${totalHours * hourlyRate}
        </div>
        <div className="text-xl font-bold">
          Tax Rate: {(taxRate * 100).toFixed(2)}%
        </div>
        <div className="text-xl font-bold">
          Total Price with Tax: ${totalPriceWithTax.toFixed(2)}
        </div>
      </div>
    </div>
  );
};

export default TechnologyCalculator;
