import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Save, X, Plus } from 'lucide-react';

const ContactDetails = ({ contact, onClose, onSave, isDarkMode }) => {
  const [editedContact, setEditedContact] = useState(contact);
  const [generatedEmails, setGeneratedEmails] = useState([]);
  const [notes, setNotes] = useState([]);
  const [newNote, setNewNote] = useState('');

  const fetchGeneratedEmails = useCallback(async () => {
    try {
      const response = await axios.get(`https://sunlab-8da6b753879c.herokuapp.com/api/generated-emails/${contact.id}`);
      setGeneratedEmails(response.data);
    } catch (error) {
      console.error('Error fetching generated emails:', error);
    }
  }, [contact.id]);

  const fetchNotes = useCallback(async () => {
    try {
      const response = await axios.get(`https://sunlab-8da6b753879c.herokuapp.com/api/contacts/${contact.id}/notes`);
      setNotes(response.data);
    } catch (error) {
      console.error('Error fetching notes:', error);
    }
  }, [contact.id]);

  useEffect(() => {
    fetchGeneratedEmails();
    fetchNotes();
  }, [fetchGeneratedEmails, fetchNotes]);

  const handleSave = async () => {
    console.log('Saving contact:', editedContact);
    try {
      const response = await axios.put(`https://sunlab-8da6b753879c.herokuapp.com/api/contacts/${contact.id}`, editedContact);
      console.log('Save response:', response.data);
      onSave(response.data);
      onClose();
    } catch (error) {
      console.error('Error saving contact:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
      }
    }
  };

  const handleAddNote = async () => {
    if (newNote.trim()) {
      try {
        await axios.post(`https://sunlab-8da6b753879c.herokuapp.com/api/contacts/${contact.id}/notes`, { content: newNote });
        setNewNote('');
        fetchNotes();
      } catch (error) {
        console.error('Error adding note:', error);
      }
    }
  };

  const inputClass = `w-full ${isDarkMode ? 'bg-gray-700 text-white' : 'bg-gray-100 text-gray-800'} rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500`;
  const buttonClass = `${isDarkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-600'} text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline`;

  return (
    <div className={`fixed inset-0 ${isDarkMode ? 'bg-gray-900' : 'bg-gray-100'} bg-opacity-75 flex justify-center items-center`}>
      <div className={`${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'} p-6 rounded-lg w-full max-w-2xl max-h-screen overflow-y-auto`}>
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">Contact Details</h2>
          <button onClick={onClose} className={buttonClass}><X size={20} /></button>
        </div>

        <div className="grid grid-cols-2 gap-4 mb-4">
          {Object.entries(editedContact).map(([key, value]) => (
            key !== 'id' && (
              <div key={key}>
                <label className="block mb-1 font-medium">{key.charAt(0).toUpperCase() + key.slice(1)}</label>
                <input
                  type="text"
                  value={value}
                  onChange={(e) => setEditedContact({ ...editedContact, [key]: e.target.value })}
                  className={inputClass}
                />
              </div>
            )
          ))}
        </div>

        <div className="mb-4">
          <h3 className="text-xl font-semibold mb-2">Generated Emails</h3>
          {generatedEmails.map((email, index) => (
            <div key={index} className={`${isDarkMode ? 'bg-gray-700' : 'bg-gray-200'} p-2 rounded mb-2`}>
              <p>{email.content.substring(0, 100)}...</p>
              <small>{new Date(email.created_at).toLocaleString()}</small>
            </div>
          ))}
        </div>

        <div className="mb-4">
          <h3 className="text-xl font-semibold mb-2">Notes</h3>
          {notes.map((note, index) => (
            <div key={index} className={`${isDarkMode ? 'bg-gray-700' : 'bg-gray-200'} p-2 rounded mb-2`}>
              <p>{note.content}</p>
              <small>{new Date(note.created_at).toLocaleString()}</small>
            </div>
          ))}
          <div className="flex mt-2">
            <input
              type="text"
              value={newNote}
              onChange={(e) => setNewNote(e.target.value)}
              placeholder="Add a note..."
              className={`${inputClass} flex-grow mr-2`}
            />
            <button onClick={handleAddNote} className={buttonClass}><Plus size={20} /></button>
          </div>
        </div>

        <button onClick={handleSave} className={`${buttonClass} w-full`}>
          <Save size={20} className="inline-block mr-2" /> Save Changes
        </button>
      </div>
    </div>
  );
};

export default ContactDetails;
