import React, { useState, useEffect, createContext, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import './globals.css';
import { Home, LogOut, Database, Clock, History, Lock, Sparkles, PackageSearch, UserRoundSearch, NotebookTabs, ChevronLast, ChevronFirst } from 'lucide-react';
import TimeTracker from './TimeTracker';
import Settings from './Settings';
import LoginButton from './LoginButton';
import Projects from './Projects';
import BusinessFinder from './BusinessFinder';
import ProjectDetails from './ProjectDetails';
import SSLChecker from './SSLChecker';
import AIEmailGenerator from './AIEmailGenerator';
import EmailGenerator from './EmailGenerator';
import Contacts from './Contacts';
import SearchLogs from './SearchLogs';
import ProfilePage from './ProfilePage';
import Dashboard from './Dashboard';
import SEOAuditTool from './SEOAuditTool';

const LoadingWave = ({ isDarkMode }) => (
  <div className={`loading-wave-container ${isDarkMode ? 'dark' : ''}`}>
    <div className="loading-wave">
      <div className="loading-bar"></div>
      <div className="loading-bar"></div>
      <div className="loading-bar"></div>
      <div className="loading-bar"></div>
    </div>
  </div>
);

const SidebarContext = createContext();

const Sidebar = ({ children, isDarkMode, toggleDarkMode, user, logout }) => {
  const [expanded, setExpanded] = useState(true);
  const navigate = useNavigate();

  return (
    <aside className="h-screen flex">
      <div className="flex-1 flex flex-col justify-between m-2.5">
        <nav className={`flex-1 flex flex-col ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'} rounded-[20px] overflow-hidden shadow-sm`}>
          <div className="p-4 pb-2 flex justify-between items-center">
            <img
              src={isDarkMode ? "/toolkit-logo-dark.png" : "/toolkit-logo-light.png"}
              className={`overflow-hidden transition-all ${expanded ? "w-32" : "w-0"}`}
              alt="Sunlab Toolkit"
            />
            <button
              onClick={() => setExpanded((curr) => !curr)}
              className={`p-1.5 rounded-lg ${isDarkMode ? 'bg-gray-700 hover:bg-gray-600' : 'bg-gray-50 hover:bg-gray-100'}`}
            >
              {expanded ? <ChevronFirst /> : <ChevronLast />}
            </button>
          </div>
          <SidebarContext.Provider value={{ expanded }}>
            <ul className="flex-1 px-3">{children}</ul>
          </SidebarContext.Provider>
          <div className="flex p-3">
            <img
              src={user.picture}
              alt="Profile"
              className="w-10 h-10 rounded-lg cursor-pointer"
              onClick={() => navigate('/profile')}
            />
            <div className={`flex justify-between items-center overflow-hidden transition-all ${expanded ? "w-52 ml-3" : "w-0"}`}>
              <div className="leading-4">
                <h4 className="font-semibold">{user.name}</h4>
                <span className={`text-xs ${isDarkMode ? 'text-gray-300' : 'text-white'}`}>{user.email}</span>
              </div>
              <button onClick={() => logout({ returnTo: window.location.origin })}>
                <LogOut size={20} />
              </button>
            </div>
          </div>
        </nav>
      </div>
    </aside>
  );
};

export function SidebarItem({ icon, text, active, alert, to }) {
  const { expanded } = useContext(SidebarContext);
  const navigate = useNavigate();

  return (
    <li
      className={`
        relative flex items-center h-11 py-2 px-3 my-1
        font-medium rounded-lg cursor-pointer
        transition-colors group
        ${
          active
            ? "bg-yellow-hover border-yellow-border border-0.5 text-gray-800"
            : "hover:bg-yellow-fill hover:border-yellow-border hover:border-0.5 text-white"
        }
      `}
      onClick={() => navigate(to)}
    >
      <div className="flex items-center justify-center w-5 h-5">
        {icon}
      </div>
      <span className={`overflow-hidden transition-all ${expanded ? "w-52 ml-3" : "w-0"}`}>
        {text}
      </span>
      {alert && (
        <div className={`absolute right-2 w-2 h-2 rounded bg-yellow-border ${expanded ? "" : "top-2"}`} />
      )}
      {!expanded && (
        <div className={`
          absolute left-full rounded-lg px-2 py-1 ml-6
          bg-yellow-fill border-yellow-border border-0.5 text-gray-800 text-sm whitespace-nowrap
          invisible opacity-20 -translate-x-3 transition-all
          group-hover:visible group-hover:opacity-100 group-hover:translate-x-0
          z-50
        `}>
          {text}
        </div>
      )}
    </li>
  );
}

const AppContent = () => {
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [settings, setSettings] = useState(null);
  const { isAuthenticated, isLoading, user, logout } = useAuth0();
  const location = useLocation();

  useEffect(() => {
    const savedSettings = localStorage.getItem('calculatorSettings');
    if (savedSettings) {
      setSettings(JSON.parse(savedSettings));
    }
  }, []);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  const handleSaveSettings = (newSettings) => {
    setSettings(newSettings);
    localStorage.setItem('calculatorSettings', JSON.stringify(newSettings));
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <LoadingWave isDarkMode={isDarkMode} />
      </div>
    );
  }

  const sidebarItems = [
    { name: 'Dashboard', icon: <Home size={20} />, route: '/' },
    { name: 'SunBeam', icon: <Sparkles size={20} />, route: '/email-generator' },
    { name: 'SunSpot', icon: <UserRoundSearch size={20} />, route: '/business-finder' },
    { name: 'Contacts', icon: <NotebookTabs size={20} />, route: '/contacts' },
    { name: 'SEO Audit', icon: <PackageSearch size={20} />, route: '/seo-audit' },
    { name: 'SSL', icon: <Lock size={20} />, route: '/ssl-checker' },
    { name: 'Projects', icon: <Database size={20} />, route: '/projects' },
    { name: 'Timecard', icon: <Clock size={20} />, route: '/timecard' },
    { name: 'Logs', icon: <History size={20} />, route: '/search-logs' },

  ];

  return (
    <div className={`h-screen ${isDarkMode ? 'dark' : ''}`}>
      {isAuthenticated ? (
        <div className="font-SpaceGrotesk flex h-screen bg-gray-100 dark:bg-gray-900">
          <Sidebar isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode} user={user} logout={logout}>
            {sidebarItems.map((item) => (
              <SidebarItem
                key={item.name}
                icon={item.icon}
                text={item.name}
                active={location.pathname === item.route}
                to={item.route}
              />
            ))}
          </Sidebar>
          <div className="flex-1 overflow-auto">
            <Routes>
              <Route path="/" element={<Dashboard isDarkMode={isDarkMode} />} />
              <Route path="/projects" element={<Projects isDarkMode={isDarkMode} />} />
              <Route path="/projects/:id" element={<ProjectDetails isDarkMode={isDarkMode} />} />
              <Route path="/timecard" element={<TimeTracker isDarkMode={isDarkMode} />} />
              <Route path="/settings" element={<Settings isDarkMode={isDarkMode} onSave={handleSaveSettings} settings={settings} />} />
              <Route path="/ai-emails" element={<AIEmailGenerator isDarkMode={isDarkMode} />} />
              <Route path="/email-generator" element={<EmailGenerator isDarkMode={isDarkMode} />} />
              <Route path="/contacts" element={<Contacts isDarkMode={isDarkMode} />} />
              <Route path="/business-finder" element={<BusinessFinder isDarkMode={isDarkMode} />} />
              <Route path="/search-logs" element={<SearchLogs isDarkMode={isDarkMode} />} />
              <Route path="/ssl-checker" element={<SSLChecker isDarkMode={isDarkMode} />} />
              <Route path="/profile" element={<ProfilePage isDarkMode={isDarkMode} />} />
              <Route path="/seo-audit" element={<SEOAuditTool isDarkMode={isDarkMode} />} />
            </Routes>
          </div>
        </div>
      ) : (
        <div className={`flex flex-col items-center justify-center min-h-screen ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-white text-black'}`}>
          <h1 className="text-4xl font-bold mb-8">Welcome to Sunlab Toolkit</h1>
          <LoginButton isDarkMode={isDarkMode} />
        </div>
      )}
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

export default App;
