import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import { UserPlus, Edit, Trash, Search, X, LayoutGrid, List, ToggleLeft, ToggleRight, ChevronDown, Loader} from 'lucide-react';
import ContactDetails from './ContactDetails';
import { CSVLink } from 'react-csv';

import { motion, AnimatePresence } from 'framer-motion';

const Dropdown = ({ label, options, onSelect, isDarkMode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative inline-block text-left " ref={dropdownRef}>
      <div>
        <button
          type="button"
          className={`inline-flex justify-between items-center rounded-lg border border-gray-300 shadow-sm px-4 py-2 ${
            isDarkMode ? 'bg-gray-700 text-white' : 'bg-white text-gray-700'
          } text-sm font-medium hover:bg-opacity-90`}
          onClick={() => setIsOpen(!isOpen)}
        >
          {label}
          <motion.div
            animate={{ rotate: isOpen ? 180 : 0 }}
            transition={{ duration: 0.3 }}
            style={{ transformOrigin: 'center' }}
            className="flex items-center justify-center"
          >
            <ChevronDown className="h-5 w-5" aria-hidden="true" />
          </motion.div>
        </button>
      </div>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
            className={`origin-top-left absolute left-0 mt-2 rounded-lg shadow-lg ${
              isDarkMode ? 'bg-gray-800' : 'bg-white'
            } ring-1 ring-black ring-opacity-5 z-50`}
          >
            <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
              {options.map((option) => (
                <button
                  key={option.label}
                  className={`block text-left px-4 py-2 text-sm ${
                    isDarkMode ? 'text-gray-300 hover:bg-gray-700' : 'text-gray-700 hover:bg-gray-100'
                  }`}
                  role="menuitem"
                  onClick={() => {
                    onSelect(option.value);
                    setIsOpen(false);
                  }}
                >
                  {option.label}
                </button>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};


const Contacts = ({ isDarkMode }) => {
  const [contacts, setContacts] = useState([]);
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [error, setError] = useState('');
  const [showContactDetails, setShowContactDetails] = useState(false);
  const [selectedContactForEdit, setSelectedContactForEdit] = useState(null);
  const [isAddingContact, setIsAddingContact] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [viewMode, setViewMode] = useState('list');
  const [csvData, setCSVData] = useState([]);
  const [isImporting, setIsImporting] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [useDataScraping, setUseDataScraping] = useState(false);
  const [industryFilter, setIndustryFilter] = useState('');
  const [assignmentFilter, setAssignmentFilter] = useState('');
  const [newContact, setNewContact] = useState({
    company: '', firstName: '', lastName: '', email: '', title: '',
    website: '', industry: '', category: '', state: '',
    proposedServices: '', personalContext: ''
  });

  const prepareCSVData = useCallback(async (contactsData) => {
    const csvData = [
      ['Company', 'First Name', 'Last Name', 'Email', 'Title', 'Website', 'Industry', 'Category', 'State', 'Proposed Services', 'Personal Context', 'Generated Email']
    ];

    for (const contact of contactsData) {
      try {
        const emailResponse = await axios.get(`https://sunlab-8da6b753879c.herokuapp.com/api/generated-emails/${contact.id}`);
        const generatedEmail = emailResponse.data[0]?.content || '';

        csvData.push([
          contact.company,
          contact.firstName,
          contact.lastName,
          contact.email,
          contact.title,
          contact.website,
          contact.industry,
          contact.category,
          contact.state,
          contact.proposedServices,
          contact.personalContext,
          generatedEmail
        ]);
      } catch (error) {
        console.error('Error fetching generated email for contact:', contact.id, error);
      }
    }

    setCSVData(csvData);
  }, []);

  const fetchContacts = useCallback(async () => {
    try {
      const response = await axios.get('https://sunlab-8da6b753879c.herokuapp.com/api/contacts');
      if (Array.isArray(response.data)) {
        setContacts(response.data);
        await prepareCSVData(response.data);
      } else {
        console.error('Unexpected response format:', response.data);
        setContacts([]);
        setError('Failed to fetch contacts. Unexpected data format.');
      }
    } catch (error) {
      console.error('Error fetching contacts:', error);
      setContacts([]);
      setError('Failed to fetch contacts. Please try again.');
    }
  }, [prepareCSVData]);

  useEffect(() => {
    fetchContacts();
  }, [fetchContacts]);

  useEffect(() => {
  setFilteredContacts(
    contacts.filter(contact =>
      (industryFilter === '' || contact.industry === industryFilter) &&
      (assignmentFilter === '' || contact.created_by === assignmentFilter) &&
      Object.values(contact).some(value =>
        value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    )
  );
}, [contacts, searchTerm, industryFilter, assignmentFilter]);

  const handleAddContact = async () => {
    try {
      let contactData = { ...newContact };

      if (useDataScraping && newContact.website) {
        const scrapedData = await axios.post('https://sunlab-8da6b753879c.herokuapp.com/api/scrape-contact', { website: newContact.website });
        contactData = {
          ...contactData,
          ...scrapedData.data,
          company: scrapedData.data.company || contactData.company,
          email: scrapedData.data.email || contactData.email,
          industry: scrapedData.data.industry || contactData.industry,
          description: scrapedData.data.description,
          socialMedia: JSON.stringify(scrapedData.data.socialMedia),
          ssl_valid: scrapedData.data.ssl_valid,
          ssl_issuer: scrapedData.data.ssl_issuer,
          ssl_subject: scrapedData.data.ssl_subject,
          ssl_valid_from: scrapedData.data.ssl_valid_from,
          ssl_valid_to: scrapedData.data.ssl_valid_to,
          ssl_last_checked: scrapedData.data.ssl_last_checked,
          ssl_error: scrapedData.data.ssl_error,
          ssl_reason: scrapedData.data.ssl_reason
        };
      }

      console.log('Contact data to be inserted:', JSON.stringify(contactData, null, 2));

      await axios.post('https://sunlab-8da6b753879c.herokuapp.com/api/contacts', contactData);
      fetchContacts();
      setNewContact({
        company: '', firstName: '', lastName: '', email: '', title: '',
        website: '', industry: '', category: '', state: '',
        proposedServices: '', personalContext: ''
      });
      setIsAddingContact(false);
    } catch (error) {
      console.error('Error adding contact:', error);
      setError('Failed to add contact. Please try again.');
    }
  };

  const handleDeleteContact = async (id) => {
    try {
      await axios.delete(`https://sunlab-8da6b753879c.herokuapp.com/api/contacts/${id}`);
      fetchContacts();
    } catch (error) {
      console.error('Error deleting contact:', error);
      setError('Failed to delete contact. Please try again.');
    }
  };

  const handleEditContact = (contact) => {
    setSelectedContactForEdit(contact);
    setShowContactDetails(true);
  };

  const handleSaveContact = async (updatedContact) => {
    try {
      await axios.put(`https://sunlab-8da6b753879c.herokuapp.com/api/contacts/${updatedContact.id}`, updatedContact);
      fetchContacts();
      setShowContactDetails(false);
      setSelectedContactForEdit(null);
    } catch (error) {
      console.error('Error updating contact:', error);
      setError('Failed to update contact. Please try again.');
    }
  };

  const importFromHubSpot = useCallback(async () => {
   setIsImporting(true);
   try {
     const response = await axios.post('https://sunlab-8da6b753879c.herokuapp.com/api/hubspot/import');
     fetchContacts();
     setError(response.data.message);
   } catch (error) {
     console.error('Error importing contacts from HubSpot:', error);
     setError('Failed to import contacts from HubSpot. Please try again.');
   } finally {
     setIsImporting(false);
   }
 }, [fetchContacts]);

 const exportToHubSpot = useCallback(async () => {
   setIsExporting(true);
   try {
     const response = await axios.post('https://sunlab-8da6b753879c.herokuapp.com/api/hubspot/export');
     setError(response.data.message);
   } catch (error) {
     console.error('Error exporting contacts to HubSpot:', error);
     setError('Failed to export contacts to HubSpot. Please try again.');
   } finally {
     setIsExporting(false);
   }
 }, []);

  const inputClass = `${isDarkMode ? 'bg-gray-700 text-white' : 'bg-gray-100 text-gray-800'} rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500`;
  const buttonClass = `${isDarkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-600'} text-white font-bold py-2 px-4 text-[14px] rounded-lg focus:outline-none focus:shadow-outline`;

  const renderContactCard = (contact) => (
  <div
    key={contact.id}
    className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} shadow-md rounded-[20px] p-6 cursor-pointer hover:shadow-lg transition-shadow duration-200 flex flex-col h-full`}
    onClick={() => handleEditContact(contact)}
  >
    <div className="flex-grow">
      <h3 className="text-xl font-semibold mb-2 truncate">{contact.company || `${contact.firstName} ${contact.lastName}`.trim()}</h3>
      <p className={`${isDarkMode ? 'text-gray-300' : 'text-gray-600'} mb-2 truncate`}>{contact.title || 'No Title'}</p>
      <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'} mb-2`}>{contact.industry || contact.category || 'No Industry'}</p>
      <p className={`${isDarkMode ? 'text-gray-300' : 'text-gray-600'} mb-1 truncate`}>
        <span className="font-semibold">Email:</span> {contact.email || 'No Email'}
      </p>
      <p className={`${isDarkMode ? 'text-gray-300' : 'text-gray-600'} mb-1 truncate`}>
        <span className="font-semibold">Phone:</span> {contact.phone_number || 'No Phone'}
      </p>
      <p className={`${isDarkMode ? 'text-gray-300' : 'text-gray-600'} mb-1 truncate`}>
        <span className="font-semibold">Website:</span> {contact.website || 'No Website'}
      </p>
      <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'} mb-1`}>
        <span className="font-semibold">State:</span> {contact.state || 'N/A'}
      </p>
      <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'} mb-4`}>
        <span className="font-semibold">Assigned To:</span> {contact.created_by || 'Unassigned'}
      </p>
    </div>
    <div className="flex justify-end space-x-2 mt-4">
      <button
        onClick={(e) => {
          e.stopPropagation();
          handleEditContact(contact);
        }}
        className={`${buttonClass} bg-yellow-fill hover:bg-yellow-hover border-yellow-border border-0.5`}
      >
        <Edit size={16} />
      </button>
      <button
        onClick={(e) => {
          e.stopPropagation();
          handleDeleteContact(contact.id);
        }}
        className={`${buttonClass} bg-red-fill hover:bg-red-hover border-red-border border-0.5`}
      >
        <Trash size={16} />
      </button>
    </div>
  </div>
);

  const renderContactList = (contact) => (
    <div key={contact.id} className={`p-4 ${isDarkMode ? 'bg-gray-800' : 'bg-white'} shadow-md rounded-lg mb-4 flex justify-between items-center`}>
      <div>
        <h3 className="text-lg font-semibold">{contact.company || `${contact.firstName} ${contact.lastName}`.trim()}</h3>
        <p className={`${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>{contact.email}</p>
        <p className={`${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>{contact.website}</p>
      </div>
      <div className="flex space-x-2">
        <button
          onClick={() => handleEditContact(contact)}
          className={`${buttonClass} bg-yellow-fill hover:bg-yellow-hover border-yellow-border border-0.5`}
        >
          <Edit size={16} />
        </button>
        <button
          onClick={() => handleDeleteContact(contact.id)}
          className={`${buttonClass} bg-red-fill hover:bg-red-hover border-red-border red-0.5`}
        >
          <Trash size={16} />
        </button>
      </div>
    </div>
  );

  return (
    <div className={`container mx-auto px-4 py-8 ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-800'}`}>
      <h1 className="text-3xl font-bold mb-6">Contacts • {filteredContacts.length}</h1>

      {error && <p className="text-red-500 mb-4">{error}</p>}

      <div className="flex flex-col md:flex-row justify-between items-center mb-6 space-y-4 md:space-y-0 md:space-x-4">
        <div className="flex items-center space-x-4 md:w-auto">
          <div className="relative md:w-64">
            <input
              type="text"
              placeholder="Search contacts..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className={`${inputClass} pl-10`}
            />
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
          </div>
          <Dropdown
            label="Industry Filter"
            options={[
              { label: 'All Industries', value: '' },
              ...Array.from(new Set(contacts.map(contact => contact.industry)))
                .filter(industry => industry) // Remove empty or undefined industries
                .map(industry => ({ label: industry, value: industry }))
            ]}
            onSelect={setIndustryFilter}
            isDarkMode={isDarkMode}
          />
          <Dropdown
              label="Assigned To"
              options={[
                { label: 'All Users', value: '' },
                ...Array.from(new Set(contacts.map(contact => contact.created_by)))
                  .filter(created_by => created_by) // Remove empty or undefined created_by values
                  .sort((a, b) => a.localeCompare(b)) // Sort usernames alphabetically
                  .map(created_by => ({ label: created_by, value: created_by }))
              ]}
              onSelect={setAssignmentFilter}
              isDarkMode={isDarkMode}
            />
          <button
            onClick={() => setViewMode(viewMode === 'cards' ? 'list' : 'cards')}
            className={`${buttonClass} bg-yellow-fill hover:bg-yellow-hover border-yellow-border border-0.5`}
          >
            {viewMode === 'cards' ? <List size={20} /> : <LayoutGrid size={20} />}
          </button>
        </div>
        <div className="flex space-x-2 md:w-auto">
          <Dropdown
            label={
              isImporting || isExporting ? (
                <span className="flex items-center">
                  <Loader className="animate-spin mr-2" size={16} />
                  {isImporting ? 'Importing...' : 'Exporting...'}
                </span>
              ) : (
                'Import/Export'
              )
            }
            options={[
              { label: 'Import from HubSpot', value: 'importHubSpot' },
              { label: 'Export to HubSpot', value: 'exportHubSpot' },
              { label: 'Export to CSV', value: 'exportCSV' },
            ]}
            onSelect={(value) => {
              if (value === 'importHubSpot' && !isImporting) importFromHubSpot();
              else if (value === 'exportHubSpot' && !isExporting) exportToHubSpot();
              else if (value === 'exportCSV') {
                document.querySelector('.csv-link').click();
              }
            }}
            isDarkMode={isDarkMode}
          />
          <button
            onClick={() => setIsAddingContact(true)}
            className={`${buttonClass} bg-green-fill hover:bg-green-hover border-green-border border-0.5`}
          >
            <UserPlus size={20} className="inline-block mr-2" /> Add New Contact
          </button>
        </div>
      </div>

      <div className={viewMode === 'cards' ? 'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6' : ''}>
        {filteredContacts.map((contact) => (
          viewMode === 'cards' ? renderContactCard(contact) : renderContactList(contact)
        ))}
      </div>

      {/* Hidden CSVLink component */}
      <CSVLink
        data={csvData}
        filename={"contacts_with_emails.csv"}
        className="hidden csv-link"
      />

      {isAddingContact && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} p-6 rounded-lg w-full max-w-md`}>
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-2xl font-bold">Add New Contact</h2>
              <button onClick={() => setIsAddingContact(false)} className="text-gray-500">
                <X size={24} />
              </button>
            </div>
            {Object.keys(newContact).map((key) => (
              <input
                key={key}
                type={key === 'email' ? 'email' : 'text'}
                placeholder={key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1').trim()}
                value={newContact[key]}
                onChange={(e) => setNewContact({...newContact, [key]: e.target.value})}
                className={`${inputClass} w-full mb-2`} // Ensure 'w-full' is included to make inputs full width
              />
            ))}
            <div className="flex items-center justify-between mb-4">
              <span>Use data scraping</span>
              <button
                onClick={() => setUseDataScraping(!useDataScraping)}
                className={`${buttonClass} flex items-center`}
              >
                {useDataScraping ? (
                  <>
                    <ToggleLeft size={20} className="mr-2" />
                    On
                  </>
                ) : (
                  <>
                    <ToggleRight size={20} className="mr-2" />
                    Off
                  </>
                )}
              </button>
            </div>
            <button onClick={handleAddContact} className={`${buttonClass} mt-4`}>
              <UserPlus size={20} className="inline-block mr-2" /> Add Contact
            </button>
          </div>
        </div>
      )}

      {showContactDetails && (
        <ContactDetails
          contact={selectedContactForEdit}
          onClose={() => setShowContactDetails(false)}
          onSave={handleSaveContact}
          isDarkMode={isDarkMode}
        />
      )}
    </div>
  );
  };

  export default Contacts;
