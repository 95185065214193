import React, { useState, useEffect } from 'react';
import { Save } from 'lucide-react';

const Settings = ({ isDarkMode, onSave }) => {
  const [settings, setSettings] = useState({
    technology: {
      defaultHourlyRate: 150,
      complexityFactors: { low: 0.8, medium: 1, high: 1.2 },
      urgencyFactors: { low: 0.9, normal: 1, high: 1.3 },
    },
    marketing: {
      defaultHourlyRate: 100,
      complexityFactors: { low: 0.8, medium: 1, high: 1.2 },
      urgencyFactors: { low: 0.9, normal: 1, high: 1.3 },
    },
  });

  useEffect(() => {
    const savedSettings = localStorage.getItem('calculatorSettings');
    if (savedSettings) {
      setSettings(JSON.parse(savedSettings));
    }
  }, []);

  const handleChange = (category, setting, value) => {
    setSettings(prevSettings => ({
      ...prevSettings,
      [category]: {
        ...prevSettings[category],
        [setting]: value,
      },
    }));
  };

  const handleFactorChange = (category, factorType, factor, value) => {
    setSettings(prevSettings => ({
      ...prevSettings,
      [category]: {
        ...prevSettings[category],
        [factorType]: {
          ...prevSettings[category][factorType],
          [factor]: parseFloat(value),
        },
      },
    }));
  };

  const handleSave = () => {
    localStorage.setItem('calculatorSettings', JSON.stringify(settings));
    onSave(settings);
  };

  const inputClass = `w-full ${isDarkMode ? 'bg-gray-700 text-white' : 'bg-gray-100 text-gray-800'} rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500`;
  const labelClass = 'block mb-2 font-medium';
  const buttonClass = `${isDarkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-600'} text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline`;

  return (
    <div className={`w-full ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'} p-6 rounded-lg font-SpaceGrotesk shadow-lg`}>
      <h2 className="text-2xl font-bold mb-6">Calculator Settings</h2>

      {['technology', 'marketing'].map((category) => (
        <div key={category} className="mb-8">
          <h3 className="text-xl font-bold mb-4 capitalize">{category} Calculator Settings</h3>

          <div className="mb-4">
            <label className={labelClass}>Default Hourly Rate</label>
            <input
              type="number"
              value={settings[category].defaultHourlyRate}
              onChange={(e) => handleChange(category, 'defaultHourlyRate', parseFloat(e.target.value))}
              className={inputClass}
            />
          </div>

          <div className="mb-4">
            <h4 className="text-lg font-semibold mb-2">Complexity Factors</h4>
            {Object.entries(settings[category].complexityFactors).map(([factor, value]) => (
              <div key={factor} className="flex items-center mb-2">
                <label className={`${labelClass} w-20 capitalize`}>{factor}</label>
                <input
                  type="number"
                  step="0.1"
                  value={value}
                  onChange={(e) => handleFactorChange(category, 'complexityFactors', factor, e.target.value)}
                  className={`${inputClass} w-24`}
                />
              </div>
            ))}
          </div>

          <div className="mb-4">
            <h4 className="text-lg font-semibold mb-2">Urgency Factors</h4>
            {Object.entries(settings[category].urgencyFactors).map(([factor, value]) => (
              <div key={factor} className="flex items-center mb-2">
                <label className={`${labelClass} w-20 capitalize`}>{factor}</label>
                <input
                  type="number"
                  step="0.1"
                  value={value}
                  onChange={(e) => handleFactorChange(category, 'urgencyFactors', factor, e.target.value)}
                  className={`${inputClass} w-24`}
                />
              </div>
            ))}
          </div>
        </div>
      ))}

      <button onClick={handleSave} className={buttonClass}>
        <Save size={20} className="mr-2 inline" /> Save Settings
      </button>
    </div>
  );
};

export default Settings;
