import React, { useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, Tooltip } from 'recharts';
import dayjs from 'dayjs';
import { ChevronDown } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import './globals.css';

const Dropdown = ({ label, options, onSelect, isDarkMode, value }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = React.useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const selectedOption = options.find(option => option.value === value);
  const displayText = selectedOption ? selectedOption.label : label;

  return (
    <div className="relative inline-block text-left w-full" ref={dropdownRef}>
      <div>
        <button
          type="button"
          className={`inline-flex justify-between items-center w-full rounded-lg border border-gray-300 shadow-sm px-4 py-2 ${
            isDarkMode ? 'bg-gray-700 text-white' : 'bg-white text-gray-700'
          } text-sm font-medium hover:bg-opacity-90`}
          onClick={() => setIsOpen(!isOpen)}
        >
          {displayText}
          <motion.div
            animate={{ rotate: isOpen ? 180 : 0 }}
            transition={{ duration: 0.3 }}
            style={{ transformOrigin: 'center' }}
            className="flex items-center justify-center"
          >
            <ChevronDown className="h-5 w-5" aria-hidden="true" />
          </motion.div>
        </button>
      </div>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
            className={`origin-top-left absolute left-0 mt-2 w-full rounded-lg shadow-lg ${
              isDarkMode ? 'bg-gray-800' : 'bg-white'
            } ring-1 ring-black ring-opacity-5 z-50`}
          >
            <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
              {options.map((option) => (
                <button
                  key={option.value}
                  className={`block w-full text-left px-4 py-2 text-sm ${
                    isDarkMode ? 'text-gray-300 hover:bg-gray-700' : 'text-gray-700 hover:bg-gray-100'
                  } ${option.value === value ? 'font-bold' : ''}`}
                  role="menuitem"
                  onClick={() => {
                    onSelect(option.value);
                    setIsOpen(false);
                  }}
                >
                  {option.label}
                </button>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const Dashboard = ({ isDarkMode }) => {
  const [sentEmails, setSentEmails] = useState(0);
  const [openedEmails, setOpenedEmails] = useState(0);
  const [emailData, setEmailData] = useState([]);
  const [contactsCount, setContactsCount] = useState(0);
  const [contactsData, setContactsData] = useState([]);
  const [scheduledEmails, setScheduledEmails] = useState(0);
  const [scheduledEmailsData, setScheduledEmailsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [timeRange, setTimeRange] = useState('last30Days');

  const timeRangeOptions = useMemo(() => [
    { label: 'Today', value: 'today' },
    { label: 'Last 7 Days', value: 'last7Days' },
    { label: 'Last 30 Days', value: 'last30Days' },
    { label: 'All Time', value: 'allTime' },
  ], []);

  const fetchDashboardData = useCallback(async () => {
    setIsLoading(true);
    try {
      const endDate = dayjs().format('YYYY-MM-DD');
      let startDate;
      switch (timeRange) {
        case 'today':
          startDate = dayjs().format('YYYY-MM-DD');
          break;
        case 'last7Days':
          startDate = dayjs().subtract(7, 'day').format('YYYY-MM-DD');
          break;
        case 'last30Days':
          startDate = dayjs().subtract(30, 'day').format('YYYY-MM-DD');
          break;
        case 'allTime':
          startDate = dayjs('2000-01-01').format('YYYY-MM-DD');
          break;
        default:
          startDate = dayjs().subtract(30, 'day').format('YYYY-MM-DD');
      }

      const [sendGridResponse, contactsResponse, scheduledEmailsResponse] = await Promise.all([
        axios.get('https://sunlab-8da6b753879c.herokuapp.com/api/sendgrid-stats', { params: { startDate, endDate } }),
        axios.get('https://sunlab-8da6b753879c.herokuapp.com/api/contacts-stats', { params: { startDate, endDate } }),
        axios.get('https://sunlab-8da6b753879c.herokuapp.com/api/scheduled-emails-stats', { params: { startDate } })
      ]);

      const sendGridData = sendGridResponse.data;
      setSentEmails(sendGridData.totalSent);
      setOpenedEmails(sendGridData.uniqueOpens);
      setEmailData(sendGridData.dailyStats);

      const contactsData = contactsResponse.data;
      setContactsCount(contactsData.totalContacts);
      setContactsData(contactsData.dailyStats);

      const scheduledEmailsData = scheduledEmailsResponse.data;
      setScheduledEmails(scheduledEmailsData.totalScheduled);
      setScheduledEmailsData(scheduledEmailsData.dailyStats);

    } catch (error) {
      console.error('Error fetching dashboard data:', error);
    } finally {
      setIsLoading(false);
    }
  }, [timeRange]);

  useEffect(() => {
    fetchDashboardData();
  }, [fetchDashboardData]);

  const formatXAxis = (tickItem) => {
    return dayjs(tickItem).format('MMM DD');
  };

  const LoadingWave = ({ isDarkMode }) => (
    <div className={`loading-wave-container ${isDarkMode ? 'dark' : ''}`}>
      <div className="loading-wave">
        <div className="loading-bar"></div>
        <div className="loading-bar"></div>
        <div className="loading-bar"></div>
        <div className="loading-bar"></div>
      </div>
    </div>
  );

  const DashboardContent = useMemo(() => () => (
    <div className="font-SpaceGrotesk flex-1 p-4 md:p-10 relative">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-bold font-SpaceGrotesk text-gray-800 dark:text-white">Dashboard</h1>
        <div className="w-48">
          <Dropdown
            label="Time Range"
            options={timeRangeOptions}
            onSelect={setTimeRange}
            isDarkMode={isDarkMode}
            value={timeRange}
          />
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 font-SpaceGrotesk gap-6 mb-6">
        {[
          { title: 'Sent Emails', value: sentEmails, data: emailData, dataKey: 'sent' },
          { title: 'Unique Opens', value: openedEmails, data: emailData, dataKey: 'opens' },
          { title: 'Contacts', value: contactsCount, data: contactsData, dataKey: 'count' },
          { title: 'Scheduled Emails', value: scheduledEmails, data: scheduledEmailsData, dataKey: 'count' },
        ].map((item) => (
          <div key={item.title} className="bg-white dark:bg-gray-800 p-6 rounded-[20px] shadow-md">
            <h2 className="text-xl font-semibold mb-2 text-gray-800 dark:text-white">{item.title}</h2>
            <p className="text-3xl font-bold text-gray-900 dark:text-white">{item.value}</p>
            <ResponsiveContainer width="100%" height={200}>
              <LineChart data={item.data}>
                <XAxis
                  dataKey="date"
                  stroke={isDarkMode ? "#ffffff" : "#374151"}
                  tickFormatter={formatXAxis}
                  style={{ fontSize: '0.75rem' }}
                />
                <YAxis
                  stroke={isDarkMode ? "#ffffff" : "#374151"}
                  style={{ fontSize: '0.75rem' }}
                />
                <Tooltip
                  contentStyle={{
                    backgroundColor: isDarkMode ? '#1F2937' : '#ffffff',
                    border: 'none',
                    color: isDarkMode ? '#ffffff' : '#374151',
                    fontSize: '0.75rem'
                  }}
                  labelFormatter={(label) => dayjs(label).format('YYYY-MM-DD')}
                />
                <Line
                  type="monotone"
                  dataKey={item.dataKey}
                  stroke="#ffe176"
                  strokeWidth={2}
                  dot={false}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        ))}
      </div>
    </div>
  ), [sentEmails, openedEmails, contactsCount, scheduledEmails, emailData, contactsData, scheduledEmailsData, isDarkMode, timeRange, timeRangeOptions]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <LoadingWave isDarkMode={isDarkMode} />
      </div>
    );
  }

  return <DashboardContent />;
};

export default Dashboard;
