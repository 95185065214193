import React, { useState } from 'react';
import axios from 'axios';
import {Search} from 'lucide-react';
const SSLChecker = ({ isDarkMode }) => {
  const [url, setUrl] = useState('');
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);

  const formatUrl = (input, protocol) => {
    if (input.startsWith('http://') || input.startsWith('https://')) {
      return input;
    }
    return `${protocol}://${input}`;
  };

  const checkSSL = async () => {
    setLoading(true);
    setResult(null);

    try {
      const httpsUrl = formatUrl(url, 'https');
      const httpUrl = formatUrl(url, 'http');

      const [httpsResponse, httpResponse] = await Promise.all([
        axios.get(`https://sunlab-dev-76f40b386902.herokuapp.com/api/check-ssl?url=${encodeURIComponent(httpsUrl)}`).catch(e => e),
        axios.get(`https://sunlab-dev-76f40b386902.herokuapp.com/api/check-ssl?url=${encodeURIComponent(httpUrl)}`).catch(e => e)
      ]);

      const httpsResult = httpsResponse.data || { valid: false, error: 'HTTPS not available' };
      const httpResult = httpResponse.data || { valid: false, error: 'HTTP not available' };

      setResult({
        https: httpsResult,
        http: httpResult,
        preferredProtocol: httpsResult.valid ? 'https' : 'http'
      });
    } catch (error) {
      setResult({
        error: 'An error occurred while checking the URL',
        details: error.message
      });
    } finally {
      setLoading(false);
    }
  };

  const displayDetailSection = (title, details) => {
    if (!details || Object.keys(details).length === 0) return null;
    return (
      <div className="mt-4">
        <h3 className="text-lg font-bold mb-2">{title}</h3>
        {Object.entries(details).map(([key, value]) => (
          typeof value === 'object' ?
            <div key={key} className="ml-4 mt-2">
              {displayDetailSection(key, value)}
            </div> :
            <p key={key} className="ml-4"><strong>{key}:</strong> {value.toString()}</p>
        ))}
      </div>
    );
  };

  const isActuallyValid = (result) => {
    return result.valid && !result.error;
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      checkSSL();
    }
  };
  const inputClass = `w-full ${isDarkMode ? 'bg-gray-700 text-white' : 'bg-gray-100 text-gray-800'} rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500`;
  const buttonClass = `${isDarkMode ? 'bg-yellow-fill hover:bg-yellow-hover border-yellow-border border-0.5' : 'bg-blue-500 hover:bg-blue-600'} text-white font-bold py-3 px-6 rounded-lg focus:outline-none focus:shadow-outline transition duration-200 ease-in-out transform hover:scale-103`;

  return (
    <div className={`ssl-checker max-w-6xl mx-auto p-6 ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-800'} min-h-screen`}>
      <h1 className="text-4xl font-bold mb-8 text-center">SSL Checker</h1>

      <div className="max-w-3xl mx-auto mb-8">
        <div className="flex flex-col md:flex-row gap-4">
          <input
            type="text"
            placeholder="Enter website URL (e.g., example.com)"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            onKeyPress={handleKeyPress}
            className={`${inputClass} flex-grow`}
          />
          <button
            onClick={checkSSL}
            disabled={loading}
            className={`${buttonClass} ${loading ? 'opacity-50 cursor-not-allowed' : ''} whitespace-nowrap`}
          >
            {loading ? 'Checking...' : 'Check SSL'}
            {!loading && <Search size={24} className="inline-block ml-2" />}
          </button>
        </div>
      </div>
      {result && !result.error && (
        <div className={`results ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'} p-6 rounded-lg shadow-md hover:shadow-lg transition duration-300`}>
          <h3 className="font-bold text-xl mb-2">Results:</h3>
          <p><strong>Preferred Protocol:</strong> {result.preferredProtocol.toUpperCase()}</p>
          <p><strong>HTTPS Available:</strong> {isActuallyValid(result.https) ? 'Yes' : 'No'}</p>
          <p><strong>HTTP Available:</strong> {isActuallyValid(result.http) ? 'Yes' : 'No'}</p>

          <h4 className="font-bold text-lg mt-4 mb-2">HTTPS Details:</h4>
          {isActuallyValid(result.https) ? (
            displayDetailSection("Certificate Details", result.https)
          ) : (
            <p>{result.https.error || 'HTTPS not available'}</p>
          )}

          <h4 className="font-bold text-lg mt-4 mb-2">HTTP Details:</h4>
          {isActuallyValid(result.http) ? (
            <p>HTTP is available, but not secure. It's recommended to use HTTPS.</p>
          ) : (
            <p>{result.http.error || 'HTTP not available'}</p>
          )}
        </div>
      )}
      {result && result.error && (
        <div className={`error ${isDarkMode ? 'bg-red-900 text-red-100' : 'bg-red-100 text-red-900'} p-6 rounded-lg shadow-md`}>
          <h3 className="font-bold text-xl mb-2">Error:</h3>
          <p>{result.error}</p>
          {result.details && <p>{result.details}</p>}
        </div>
      )}
    </div>
  );
};

export default SSLChecker;
